export interface PermissionSet {
  [resource: string]: string[];
}

export enum Resource {
  CELL = 'CELL',
  CONSOLE = 'CONSOLE',
  CUSTOMER = 'CUSTOMER',
  DATASOURCE = 'DATASOURCE',
  INTEGRATION = 'INTEGRATION',
  SOLUTION = 'SOLUTION',
  USER = 'USER',
  MANAGEDFUSION_SYSTEM = 'MANAGEDFUSION_SYSTEM',
  MANAGEDFUSION_INTEGRATION = 'MANAGEDFUSION_INTEGRATION',
  SELFHOSTEDFUSION_INTEGRATION = 'SELFHOSTEDFUSION_INTEGRATION',
  SELFHOSTEDFUSION_SYSTEM = 'SELFHOSTEDFUSION_SYSTEM',
  ML_MODEL_DEPLOYMENT = 'ML_MODEL_DEPLOYMENT',
  ML_MODEL = 'ML_MODEL',
  SERVICE_ACCOUNTS = 'SERVICE_ACCOUNTS',
}

export enum Action {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
  VIEW_GCP_LINKS = 'VIEW_GCP_LINKS',
}

export interface PermissionSetGroup {
  [group: string]: PermissionSet | { [id: string]: PermissionSet };
}

export interface PermissionContext {
  customerId: string;
  appId: string;
}
