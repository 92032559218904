import { Action, PermissionContext, PermissionSet, Resource } from './interfaces';

export class PermissionService {
  private static _permissionSet: PermissionSet = {};

  static setPermissions(permissionSet: PermissionSet) {
    this._permissionSet = permissionSet;
  }

  static setPermissionContext(context: PermissionContext) {
    this._permissionContext = context;
  }

  static addPermission(resource: Resource, action: Action) {
    if (this._permissionSet[resource] && !this._permissionSet[resource].includes(action)) {
      this._permissionSet[resource].push(action);
    } else {
      this._permissionSet[resource] = [action];
    }
  }

  static removePermission(resource: Resource, action: Action) {
    if (this._permissionSet[resource]?.includes(action)) {
      const i = this._permissionSet[resource].indexOf(action);
      this._permissionSet[resource].splice(i, 1);
    }
  }

  static hasPermission(resource: Resource | string, action: Action) {
    return !!this._permissionSet[resource]?.includes(action);
  }

  static getPermissionContext = () => PermissionService._permissionContext;
  private static _permissionContext: PermissionContext = { customerId: undefined, appId: undefined };
}
